<template>
    <div class="w-screen">
        <div class="banner w-screen flex items-center justify-center">
            <div class="text-center lg:py-20">
                <div class="px-10 animate__slow" id="banner">
                    <p
                        class="
                            font-bold
                            text-3xl
                            lg:text-4xl
                            text-white
                            tracking-wider
                        "
                    >
                        服務夥伴
                    </p>
                    <p class="mt-2 text-md text-blue lg:text-2xl">
                        OUR CLIENTS
                    </p>
                </div>
                <div class="blue-line h-1 w-full bg-blue mt-4"></div>
            </div>
        </div>
        <div class="page lg:px-8">
            <div
                class="m-auto max-w-6xl flex flex-wrap items-center text-center"
            >
                <div
                    class="flex-triple lg:flex-quater md:px-8 px-1 py-4"
                    v-for="img in imgs"
                    :key="`p_${img}`"
                >
                    <img :src="`/images/partners/p_${img}.png`" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import animate from './../animate';
const hiddeIds = [0, 78, 80, 86, 87, 88, 107];

export default {
    data() {
        return {
            imgs: Array.from(Array(117).keys()).filter(
                (v) => !hiddeIds.includes(v)
            ),
        };
    },
    mounted() {
        if (window.IntersectionObserver) {
            new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        animate('#banner', 'fadeIn');
                    }
                });
            }).observe(document.getElementById('banner'));
        }
    },
};
</script>
<style lang="scss" scoped>
.banner {
    background: url('/images/banner.jpg') no-repeat center center;
    background-size: cover;
    min-height: 200px;
}
</style>
